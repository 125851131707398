<main>
	<img id="logo" src="/logo.svg" alt="logo"/>
	<div id="hero">
		<b>We build beautiful tech.</b>
		Simple as that.
	</div>
	<div id="subheader">
		Idea, design, deployment, and everything in between. We’ve got you covered every step of the way.
	</div>
	<a id="contact" href="mailto:jamohile@gmail.com?Subject=Getting%20in%20Touch%20with%20Oxeolo!">Get in Touch </a>
	<img id="wordmark" src="/wordmark.svg" alt="wordmark"/>
</main>

<style>
	main {
		padding: 50px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@keyframes float-entry {
		from {
			top: 50px;
			opacity: 0;
		}
		to {
			top: 0px;
			opacity: 1;
		}
	}

	#logo {
		position: relative;
		width: 50px;
		margin-bottom: 48px;
		animation: float-entry 0.5s forwards ease-out;
	}

	#hero {
		position: relative;
		font-size: 30px;
		color: white;
		font-weight: 400;
		margin-bottom: 24px;
		animation: float-entry 1s forwards ease-out;
	}
	#subheader {
		position: relative;
		font-size: 20px;
		color: white;
		animation: float-entry 1.5s forwards ease-out;
	}

	#contact {
		position: relative;
		display: block;
		background: #5AACFF;
		box-shadow: 0px 4px 0px #529CE3;
		width: fit-content;
		margin-top: 48px;
		width: 100%;
		padding: 12px 0px;
		color: white;
		font-weight: 600;
		border-radius: 8px;
		animation: float-entry 2s forwards ease-out;
	}

	#wordmark {
		bottom: 0;
		position: absolute;
		width: 100px;
		margin-bottom: 48px;
	}

	@media (min-width: 800px) {
		main {
			padding: 75px 0px;
			width: 700px;
			margin-left: auto;
			margin-right: auto;
		}
		#hero {
			font-size: 60px;
		}
		#subheader {
			font-size: 30px;
		}

		#contact {
			width: 200px;
		}
	}

	@media (max-height: 700px){
		#wordmark {
			display: none;
		}
	}
</style>